<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left">

            <!-- general tab -->
            <b-tab active>

                <!-- title -->
                <template #title>
                    <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
                    <span class="font-weight-bold">General</span>
                </template>

                <b-card title="Configuración General" sub-title="Realice cambios con precaución para evitar posibles errores en el sistema.">
                    <form @submit.prevent="submitSettings">
                        <b-row>                        
                            <b-col v-for="(setting, index) in settings" :key="index" cols="6" class="mb-1">
                                <b-form-group :label="setting.type === 'checkbox' ? '' : setting.descripcion" :label-for="setting.type === 'checkbox' ? `check-button-${index + setting.configuracion_Id}` : `text-input-${index + setting.configuracion_Id}`">
                                    <template v-if="setting.type === 'checkbox'">
                                        <b-form-checkbox
                                            v-model="setting.valor"
                                            :id="`check-button-${index + setting.configuracion_Id}`"
                                            :name="`check-button-${index + setting.configuracion_Id}`" switch inline>
                                            <span>{{ setting.descripcion }}</span>
                                        </b-form-checkbox>
                                    </template>
                                    <template v-else>
                                        <b-form-input
                                            v-model="setting.valor"
                                            type="text"
                                            placeholder="Ingrese un valor"
                                            :id="`text-input-${index + setting.configuracion_Id}`"
                                            :name="`text-input-${index + setting.configuracion_Id}`"
                                            required
                                        ></b-form-input>
                                    </template>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-button type="submit" variant="primary">Guardar</b-button>
                    </form>
                </b-card>
            </b-tab>
            <!--/ general tab -->

        </b-tabs>
    </b-overlay>
</template>

<script>
import {
    BTabs, 
    BTab,
    BCard,
    BRow,
    BCol,
    BForm,
    BInputGroupPrepend, 
    BInputGroupAppend, 
    BInputGroup,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip, 
    VBTooltip,
    VBToggle,
    BSpinner,
    BSidebar,
    BOverlay,
    BFormCheckbox

} from "bootstrap-vue";
import store from '@/store'
import SettingsProvider from '@/providers/Settings'
const SettingsResource = new SettingsProvider()

export default {
    components: {
        BTabs, 
        BTab,
        BCard,
        BRow,
        BCol,
        BForm,
        BInputGroupPrepend, 
        BInputGroupAppend, 
        BInputGroup,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BTooltip, 
        VBTooltip,
        VBToggle,
        BSpinner,
        BSidebar,
        BOverlay,
        BFormCheckbox
    },
    data() {
        return {
            loading: false,
            options: {},
            settings: [],
        }
    },
    beforeCreate() {

    },
    async mounted() {
        await this.getSettings()
    },
    methods: {
        async getSettings() {
            this.loading = true            
            const { data } = await SettingsResource.getByCompany()
            this.loading = false

            if (data.isSuccesful) {  
                
                store.dispatch('auth/UPDATE_SETTING', data.data)
                
                //Mapear y asignar un nuevo key que indique si es un checkbox o un input de tipo texto o número, 
                //para poder mostrar el componente adecuado y los que empiecen con ACTIVAR significa que son checkbox
                this.settings = _.orderBy(data.data.map(setting => {
                    return {
                        ...setting,
                        type: _.startsWith(setting.clave, 'ACTIVAR') ? 'checkbox' : 'text',
                        valor: _.startsWith(setting.clave, 'ACTIVAR') ? (setting.valor != 0) : setting.valor
                    }
                }), ['type'], ['asc'])

                //Guardamos las configuraciones en el storage
                localStorage.setItem("oldSettings", JSON.stringify(this.settings));  
            } else {
                this.danger('Error al obtener la configuración, intentelo de nuevo')
            }
        },
        async submitSettings() {
            
            //Ahora necesito saber o extraer solo las configuraciones que han cambiado comparando con las que estan en el storage
            const oldSettings = JSON.parse(localStorage.getItem("oldSettings"));
            const newSettings = this.settings;
            const settings = newSettings.filter((setting, index) => {
                return setting.valor != oldSettings[index].valor;
            });

            // Crea una lista de objetos de configuración, cada uno con clave y valor
            const settingsDTO = settings.map(setting => {
                return {
                    configuracion_Id: setting.configuracion_Id, // Asigna el ID apropiado si es necesario
                    empresa_Id: setting.empresa_Id,       // Asigna el ID de empresa apropiado si es necesario
                    sucursal_Id: setting.sucursal_Id,      // Asigna el ID de sucursal apropiado si es necesario
                    clave: setting.clave,
                    valor: _.startsWith(setting.clave, 'ACTIVAR') ? (setting.valor === true ? "1" : "0") : String(setting.valor),  // Convierte cualquier valor a string
                    descripcion: setting.descripcion // Asigna una descripción adecuada
                }
            });

            this.loading = true
            try {
                //Enviamos las configuraciones que han cambiado
                const { data } = await SettingsResource.update(settingsDTO)
                if (data.isSuccesful) {
                    this.success('Configuración actualizada correctamente')
                    await this.getSettings()
                } else {
                    this.danger('Error al actualizar la configuración, intentelo de nuevo')
                }
            } catch (error) {                
                console.error(error)
            } finally {
                this.loading = false
            }
        },       
    },
    watch: {
       
    }
}
</script>